import React, { useEffect, useState } from 'react'
import { FooterTemplate, Template } from '../components';
import { API_ENDPOINT, AUTH_HEADERS, Images } from '../config';
import axios from 'axios';
import { getData } from '../utils';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router';

const Email = () => {


    const navigate = useNavigate();
    const [email, setEmail] = useState({
        email: ''
    });
    const [valid, setValid] = useState(false);
    const [emailType, setEmailType] = useState('');

    const toastMessage = (toastMessage) => {
        toast(toastMessage);
    }

    useEffect(() => {


        getData('clientId').then((clientId) => {
            console.log(clientId);
        }).catch((error) => {
            navigate('/');
        })

        getData('userEmail').then((userEmail) => setEmail({...email, email: userEmail}))

        getData('domain').then((res) => {
                setEmailType(res);
            }).catch((error) => console.log(error));

        getData('resultData').then((res) => {
            getData('paymentSucceed').then((payment) => {
                if(res.payWall === 1 && !payment) {
                    navigate('/paywall');
                }
            }).catch((error) => {
                navigate('/questions')
            })
        }).catch((error) => {
            navigate('/questions')
        })
    }, [])

    const sendEmail = (e) => {
        console.log("hit")
        e.preventDefault();
        e.stopPropagation();

        // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.email) && email.email.split('@')[1] === emailType && emailType !== 'any') {

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email.email)) {
            setValid(true);
        } else {
            toast('Enter the valid email')
        }
    }


    useEffect(() => {
        console.log(valid);
        if(valid) {
            getData('clientId').then((res) => {
                getData('clientUserId').then((clientUser) => {
                    axios.get(`${API_ENDPOINT}/third-party/assessment/${clientUser}/summary/report?email=${email.email}`, {
                        email,
                        headers: {
                            ...AUTH_HEADERS,
                            'clientId': res
                        }
                    }).then((response) => { toastMessage(JSON.stringify(response.data.success.message)); setEmail({ email: '' }); setValid(false) })
                        .catch((error) => {
                            toast(error)
                        })
                })
            })
        } else {
            console.log("hitt");
        }
        
    }, [valid])

    const onChange = (e) => {
        setEmail({email: e.target.value});
    }

    return (
        <>
            <div className="result-template">
                <Template
                    title={'Send your report to your email or view your report'}
                    description={''}
                    type={"email"}
                    footer={true}
                    emailFun={sendEmail}
                    emailChange={onChange}
                    emailVal={email.email}
                    validData={valid}
                />
            </div>
            <ToastContainer />
            <FooterTemplate page={'email'} title="Learn more about lumenai" description="Nunc vestibuilum porta lacus, vel pulvinar massa rhoncus et." url="#" />
        </>
    )
}

export default Email