import React, { useEffect, useState } from 'react';
import { Template } from '../components';
import { setData } from '../utils';
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT, AUTH_HEADERS } from '../config';


const Start = () => {
    let location = useParams();
    let [newLocation, setNewLocation] = useSearchParams();
    let [active, setActive] = useState(false);
    let navigate = useNavigate();

    let source = newLocation.get('source');
    if (location.clientId) {
        setData('clientId', location.clientId)
    } else {
        navigate('/error')
    }

    if (source && source !== 'undefined') {
        setData('clientUrl', source)
    } else {
        setData('clientUrl', '#')
    }


    useEffect(() => {
        axios.get(`${API_ENDPOINT}/third-party/client/details`, {
            headers: {
                ...AUTH_HEADERS,
                'clientId': location.clientId,
            }
        }).then((res) => {
            setActive(true);
            setData('domain',res.data.success.data.clientDetails.domain);
        }).catch((error) => {setActive(false); navigate('/error')})
    }, [])

    return (
        <>
            {active ? (
                <div className='start'>

                    <Template
                        // title={'Humanise your data and unlock the unique value of your transferrable skills'}
                        title={'Objective human capability indexing for workforce analytics and organisational transformation'}
                        description={''}
                        type={'start'}
                        location={location.clientId}
                    />
                    {/* <img className="infinity" src={Images.InfinityWhite} /> */}
                </div>
            ) : null}
        </>
    )
}

export default Start;